<script setup lang="ts">
const authStore = useAuthStore();
const { $promotionService } = useServices();

const promotions = computed(() => {
  return [$promotionService.getGenericWheelCount()];
});

watchEffect(async () => {
  if (authStore.loggedIn)
    await Promise.allSettled(promotions.value).catch((e) => console.error(e));
});
</script>

<template>
  <div></div>
</template>

<style scoped lang="scss"></style>
